import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';

import createMarkup from '../../helpers/createMarkup';
import responsive from '../../helpers/responsive';
import { object, string } from 'prop-types';

const NavItemWrapper = styled(Link)`
  color: black;
  text-decoration: none;
  transition: all 100ms ease-in;

  &:hover {
    text-decoration: underline;
  }
`;

const NavItemTitle = styled.h3`
  font-size: 24px;
  font-weight: 400;
  margin-bottom: 6px;
  text-transform: uppercase;
`;

const NavItemText = styled.div`
  line-height: 1.4;

  ${responsive('600px', {
    textAlign: 'left',
  })}
`;

const NavItem = props => {
  const { page, title, excerpt } = props;

  return (
    <NavItemWrapper {...props} to={page ? page.node.path : null}>
      <NavItemTitle
        dangerouslySetInnerHTML={createMarkup(page ? page.node.title : title)}
      />
      <NavItemText
        dangerouslySetInnerHTML={createMarkup(
          page ? page.node.excerpt : excerpt,
        )}
      />
    </NavItemWrapper>
  );
};

NavItem.propTypes = {
  page: object.isRequired,
  title: string,
  excerpt: string,
};

NavItem.defaultProps = {
  title: undefined,
  excerpt: undefined,
};

export default NavItem;

import React from 'react';
import { arrayOf, object } from 'prop-types';
import styled from 'styled-components';

import responsive from '../../helpers/responsive';

import NavItem from './NavItem';

const NavWrapper = styled.div`
  display: grid;
  grid-column: 4 / 14;
  grid-template-columns: 1fr 1fr;
  grid-gap: 36px 24px;

  ${responsive('600px', {
    gridTemplateColumns: '1fr',
  })}

  ${responsive('960px', {
    gridColumn: '2 / 16',
  })}
`;

const Nav = ({ pages }) => {
  return (
    <NavWrapper>
      {pages.map(
        page =>
          page.node.title !== 'Home' && (
            <NavItem page={page} key={page.node.id} />
          ),
      )}
      <NavItem
        href="https://karenvogelcombr.wordpress.com/"
        excerpt="Textos e divulgações"
        title="Blog"
        as="a"
        target="_blank"
      />
    </NavWrapper>
  );
};

Nav.propTypes = {
  pages: arrayOf(object).isRequired,
};

export default Nav;

import React from 'react';
import { graphql } from 'gatsby';

import BaseLayout from '../components/BaseLayout/BaseLayout';
import Nav from '../components/Nav/Nav';

function App(props) {
  const { edges } = props.data.allWordpressPage;

  return (
    <BaseLayout>
      <Nav pages={edges} />
    </BaseLayout>
  );
}

export const query = graphql`
  query HomeQuery {
    allWordpressPage(sort: { order: DESC, fields: date }) {
      edges {
        node {
          id
          title
          slug
          excerpt
          path
        }
      }
    }
  }
`;

export default App;
